<template>
  <v-dialog max-width="600" :fullscreen="$vuetify.breakpoint.width < 600" v-model="openDialogRepro">
    <v-card>
      <v-row class="ma-0 py-0">
        <v-col cols="12" sm="10" md="10" class="mt-3">
          <h2>Commander une reproduction</h2>
          <small>
            Pour commander une ou plusieurs reproduction de mes oeuvres, 
            merci d'indiquer le support et la taille que vous souhaitez commander :
          </small>
        </v-col>
      </v-row>

      <!-- <v-row class="mx-1 mt-0" v-if="product.galleryRepro.length > 0">
        <v-col cols="12" class="py-0"><h1>{{ product.name }}</h1></v-col>
        <template v-for="(item, i) in product.galleryRepro">
          <v-col cols="6" sm="6" :key="i" class="mx-auto px-1">
            <v-img :src="baseUrl() + item" max-height="140" style="border-radius:4px;"/>
          </v-col>
        </template>
      </v-row> -->

      <v-row class="ma-0 mt-5">
        <v-col cols="12" class="">
          <!-- <v-card elevation="0" outlined class="pa-3"> -->
            <h2>Sélectionnez votre support :</h2>
            <v-row>
              <v-col cols="12" class="">
                <v-select v-model="support" :items="supportTypeAvailables()" outlined @change="selectSupport"
                          :item-value="'value'" :item-text="'label'"></v-select>
              </v-col>
            </v-row>
          <!-- </v-card> -->
          <v-card elevation="0" outlined class="pa-3">
            <h2>Sélectionnez votre taille :</h2>
            <v-row>
              <v-radio-group v-model="size">
                <template v-for="(reproConf, k) in product.reproAvailables">
                  <v-col cols="12" sm="12" class="py-1" :key="k" v-if="reproConf.type == support">
                    <!-- <v-btn large block elevation="0" :outlined="size!=reproConf.sizeNum" :dark="size==reproConf.sizeNum" class="mb-2" 
                          :color="size==reproConf.sizeNum? 'blue' :''"
                            @click="selectSize(reproConf)"> {{ reproConf.sizeStr }}
                    </v-btn> -->
                    <!-- <b>{{ reproConf.sizeStr }}</b> -->
                    <v-radio large block elevation="0" class="mb-2 d-inline" :value="reproConf.sizeNum "
                             :label="reproConf.sizeStr"> {{ reproConf.sizeStr }}
                    </v-radio>
                    <b class="font-dancing font-large ml-3" @click="selectSize(reproConf)">{{ reproConf.price }}€</b>
                  </v-col>
                </template>
              </v-radio-group>
            </v-row>
          </v-card>
          <p class="pa-3 mb-0" v-if="supportDesc!='' && supportDesc != null">{{ supportDesc }}</p>
        </v-col>

        <v-col cols="12" class="">
          <v-card elevation="0" outlined class="pa-3">
            <v-row>
              <v-col cols="12" md="6" class="pb-0">
              <h2>Nombre d'exemplaires :</h2>
              </v-col>
              <v-col cols="12" md="6" class="pb-0">
                <v-text-field outlined dense v-model="quantity" type="number"></v-text-field>
              </v-col>
            </v-row>
          </v-card>
        </v-col>

        <v-col cols="12" class="pb-0 px-5 text-right">
          <i v-if="fraisDePort > 0">Frais de port : {{ fraisDePort }}€</i>
          <i v-else>Frais de port offerts</i>
        </v-col>
        <v-col cols="12" class="pb-0 px-5 text-right">
          <b style="font-size:20px;">Total : {{ totalPrice }}€</b>
        </v-col>

        <v-col cols="12" class="pb-0">
          <v-btn block dark color="green" elevation="0" @click="addToBasket">
            <v-icon small class="mr-1">mdi-basket-plus</v-icon> Ajouter au panier
          </v-btn>
          <br>
        </v-col>
              

        <template v-if="product.galleryRepro.length == 0">
          <v-col cols="12" class="pb-0">
            <h3>Exemples de reproduction :</h3>
          </v-col>

          <v-col cols="12" sm="6" class="px-xs-1 pr-sm-1">
            <v-img src="@/assets/img/reproductions/repro-exemple1.jpg"/>
          </v-col>
          <v-col cols="12" sm="6" class="px-xs-1 pl-sm-1">
            <v-img src="@/assets/img/reproductions/repro-exemple2.jpg"/>
          </v-col>
        </template>
      </v-row>

      <v-toolbar class="px-3 toolbar-popup" style="position:absolute; top:0%;" color="transparent" height="70" elevation="0">
        <v-spacer/>
        <v-btn icon  outlined @click="closeDialogRepro" >
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
    </v-card>
  </v-dialog> 

</template>


<script>

//import axios from 'axios'
//import router from '../router/router'
import core from '../../plugins/core.js'


export default {
  name: 'product',
  components: {  },
  props: ['showDialogRepro', 'closeDialogRepro', 'product'],
  data: () => ({
    openDialogRepro: false,

    support: 'papier',
    size: 1,
    quantity: 1,
    fraisDePort: 0,

    supportDesc: "",

    supportTypes:[{
        "value": "papier",
        "label": "Papier"
      },
      {
        "value": "sticker",
        "label": "Sticker (autocolant)"
      },
      {
        "value": "toile_chassi",
        "label": "Toile tendue sur châssis"
      }
    ],
  }),
  async mounted(){
    this.support = this.product.reproAvailables[0] != null ? this.product.reproAvailables[0].type : 'papier'
  },
  methods: {
    addToBasket(){
      this.product.repro = { support : this.support, sizeNum: this.size, quantity: this.quantity }
      this.$store.dispatch('basket/addProduct', this.product)
      this.$router.push("/panier")
    },
    selectSize(reproConf){
      this.size = reproConf.sizeNum
      this.fraisDePort = reproConf.fraisDePort
    },
    selectSupport(){
      //console.log("selectSupport", this.support, this.size, this.$store.state.basket.reproConfig[this.support])
      if(this.$store.state.basket.reproConfig[this.support] != null)
      this.$store.state.basket.reproConfig[this.support].forEach((repro)=>{
        //console.log("reproConfig???", repro.sizeNum, this.size)
        if(repro.sizeNum == this.size){
           this.fraisDePort = repro.fraisDePort
           this.supportDesc = repro.description
        }
      })
    },
    supportTypeAvailables(){
      let availables = []
      this.supportTypes.forEach((support) => {
        this.product.reproAvailables.forEach((repro) => {
          //console.log("supportTypeAvailables", repro, support, this.support)
          if(repro.type == support.value){
            availables.push(support)
            //if(availables.length == 1 && this.support == "") this.support = support.value
          }
        })
      })
      return availables
    },
    nl2br: function(str, is_xhtml){
      return core.nl2br(str, is_xhtml)
    },
    baseUrl(){ return core.baseUrl() }

  },
  computed: {
    totalPrice() { 
      let conf = null 
      if(this.$store.state.basket.reproConfig[this.support] != null)
      this.$store.state.basket.reproConfig[this.support].forEach((repro)=>{
        if(repro.sizeNum == this.size) conf = repro
      })
      if(conf == null) return 0
      else return (this.quantity * conf.price) + conf.fraisDePort
    },
  },
  watch: {
      'showDialogRepro': { immediate: true, 
          async handler () {
            //console.log("showDialogRepro changed",  this.showDialogRepro)
              this.openDialogRepro = this.showDialogRepro
          }
      },
      'openDialogRepro': { immediate: true, 
          async handler () {
            //console.log("openDialogRepro changed",  this.openDialogRepro)
              if(this.openDialogRepro == false) this.closeDialogRepro()
          }
      },
      'size': { immediate: true, 
          async handler () {
            //console.log("size changed",  this.size)
            this.selectSupport()
          }
      },
      'quantity': { immediate: true, 
          async handler () {
            if(this.quantity < 1) setTimeout(()=>{ this.quantity = 1 }, 100)
          }
      },
  }, 
}
</script>
