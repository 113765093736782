<template>
  <v-col cols="12" class="mx-auto" lg="12">
    <h2 v-if="comments.length > 0">
      <v-icon>mdi-comment</v-icon> {{ comments.length }} commentaire<template v-if="comments.length > 1">s</template>
    </h2>
    <h2 v-else>
      <v-icon>mdi-comment</v-icon> Aucun commentaire
    </h2>
    
    <br>

    <template v-for="(comment, c) in comments">
      <v-card outlined :key="c" class="my-2">
        <v-col cols="12">
        {{ comment.authorName }}<br>
        <small v-html="nl2br(comment.text)"></small>
        </v-col>
      </v-card>
    </template>

    <template v-if="showForm">
      <v-divider class="my-5"></v-divider>
      <h2><v-icon>mdi-chevron-down</v-icon> Votre commentaire</h2><br>

      <v-card flat>
        <v-col cols="12">
          <template v-if="!msgSent">
            <v-text-field  outlined v-model="authorName" label="Votre nom*"></v-text-field>
            <v-text-field  outlined v-model="authorEmail" label="Votre adresse email"></v-text-field>
            <v-textarea  outlined v-model="text" label="Votre commentaire*"></v-textarea>
          </template>
          <v-toolbar flat color="transparent" height="40" class="py-0">
            <span class="green--text" v-if="msgSent">
              <v-icon color="green">mdi-check</v-icon> Commentaire envoyé
            </span>
            <v-spacer/>
            <v-btn color="primary" elevation="0" v-if="!msgSent && !msgSending" @click="sendComment">
              <v-icon>mdi-send</v-icon> Envoyer
            </v-btn>
          </v-toolbar>
        </v-col>
      </v-card>
    </template>

    <v-toolbar elevation="0" color="transparent" dense>
      <v-spacer/>
      <v-btn color="primary" elevation="0" outlined @click="showForm = !showForm" class="mt-4 mr-3">
        <v-icon>mdi-plus-circle</v-icon> Laisser un commentaire
      </v-btn>
    </v-toolbar>

  </v-col>
</template>

<style></style>

<script>

import axios from 'axios'
//import router from '../router/router'
import core from '../../plugins/core.js'

//import Post from '@/components/Post.vue'

export default {
  name: 'commentblock',
  components: {  },
  props: ['productId'],
  data: () => ({
    comments: [],
    authorName: "",
    authorEmail: "",
    text: "",
    showForm: false,
    msgSent: false,
    msgSending: false
  }),
  async mounted(){
    this.loadComments()
  },
  methods: {
    async loadComments(){
      this.comments = await this.$store.dispatch('app/fetchEntities', 
                                                  { entityType: "comment", 
                                                    query: { product: this.productId, show: true }, 
                                                    sort: { 'created' : -1 } 
                                                  })
    },
    
    async sendComment(){
      this.msgSending = true
      const { data } = await axios.post('/comment/create', { authorName: this.authorName, 
                                                             authorEmail: this.authorEmail, 
                                                             text: this.text,
                                                             productId: this.productId })
      if(data.error == false){
        this.msgSent = true
        this.loadComments()
      }
      this.msgSending = false
    },
    nl2br: function(str, is_xhtml){
      return core.nl2br(str, is_xhtml)
    },

  },
  computed: {
    
    
  },
  watch: {
      'productId': { immediate: true, 
          async handler () {
              if(this.productId != null)
                this.loadComments()
          }
      },
  }, 
}
</script>
